import { Buffer } from "buffer";

import { getSession } from "@/helpers/sessionToken";
import { EnvVariables } from "@/types";

export const generateNonce = () => {
  let array = new Uint32Array(1);
  globalThis.crypto.getRandomValues(array);
  return array[0];
};
export const authUrl = (envs: EnvVariables, isAdmin = false) => {
  return (
    `${envs.authHost}/protocol/openid-connect/auth` +
    `?nonce=${generateNonce()}` +
    `&client_id=${isAdmin ? envs.adminAuthClientId : envs.authClientId}` +
    `&response_type=${envs.authResponseType}` +
    `&redirect_uri=${envs.appHost}/auth/callback${isAdmin ? "?admin" : ""}` +
    `&scope=${envs.authScope}`
  );
};

export const jwtDecode = (token: string): Record<string, any> => {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
};

export const objectToQueryString = (object: Record<string, string>) => {
  const newSearchParams = new URLSearchParams(object);
  return `?${newSearchParams.toString()}`;
};

export const createFilteredQueryParams = (obj: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => (typeof value === "boolean" && true) || !!value),
  );
};

export const getTitleHeader = (tenantName: string) => {
  switch (tenantName) {
    case "accountvalidate":
      return "Account Validate";
    default:
      return "CoP";
  }
};

export const getClientType = () => {
  const sessionData = getSession();
  const userType = sessionData && jwtDecode(sessionData.access_token).client_id;
  return userType;
};

export const decodeHtmlEntities = (str: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  return doc.documentElement.textContent;
};
