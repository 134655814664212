"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";

import { Box, Theme, Link as MuiLink, Typography, TypographyOwnProps } from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";

import { archivo } from "@/config/theme";
import { useEnvVariables } from "@/config/EnvVariablesProvider";
import { isGtb, isFirstBank, isPrivalgo, isCmb, isScb, isReliance, isCanaraBank, isBankAbc, isHlBank, isSoldo, isQib, isMelton, isEab, isVitesse, isZenith, isBirmingham } from "@/helpers/constants";
import { Routes } from "@/types";

const getLogoStyles = (tenantName: string) => {
  if (isGtb(tenantName)) {
    return {
      maxHeight: "55px",
      height: "50px",
      width: "90px",
    };
  } else if (isFirstBank(tenantName)) {
    return {
      maxHeight: "55px",
      height: "55px",
      width: "150px",
    };
  } else if (isCmb(tenantName)) {
    return {
      maxHeight: "64px",
      height: "64px",
      width: "224px",
    };
  } else if (isScb(tenantName)) {
    return {
      maxHeight: "80px",
      height: "75px",
      width: "220px",
    };
  } else if (isReliance(tenantName)) {
    return {
      maxHeight: "80px",
      height: "62px",
      width: "175px",
    };
  } else if (isCanaraBank(tenantName)) {
    return {
      maxHeight: "60px",
      height: "60px",
      width: "120px",
    };
  } else if (isBankAbc(tenantName)) {
    return {
      maxHeight: "60px",
      height: "60px",
      width: "100%",
    };
  } else if (isHlBank(tenantName)) {
    return {
      maxHeight: "50px",
      height: "50px",
      width: "100%",
    };
  } else if (isSoldo(tenantName)) {
    return {
      maxHeight: "70px",
      height: "70px",
      width: "160px",
    };
  } else if (isQib(tenantName)) {
    return {
      maxHeight: "60px",
      height: "58px",
      width: "80px",
    };
  } else if (isMelton(tenantName)) {
    return {
      maxHeight: "60px",
      height: "55px",
      width: "100%",
    };
  } else if (isEab(tenantName)) {
    return {
      maxHeight: "60px",
      height: "25px",
      width: "266px",
    };
  } else if (isVitesse(tenantName)) {
    return {
      maxHeight: "45px",
      height: "45px",
      width: "266px",
    };
  } else if (isZenith(tenantName)) {
    return {
      maxHeight: "65px",
      height: "63px",
      width: "100%",
    };
  } else if (isBirmingham(tenantName)) {
    return {
      maxHeight: "50px",
      height: "48px",
      width: "140px",
    };
  } else {
    return {
      maxHeight: "40px",
      height: "40px",
      width: "100%",
    };
  }
};

export const link: SystemStyleObject<Theme> = {
  display: "flex",
  alignItems: "center",
  fontFamily: archivo.style.fontFamily,
  fontWeight: 700,
  textDecoration: "none",
  width: "max-content",
};

export const span: SystemStyleObject<Theme> = {
  fontFamily: archivo.style.fontFamily,
  fontWeight: 700,
};

interface Props {
  color?: TypographyOwnProps["color"];
  size?: TypographyOwnProps["fontSize"];
}

const Logo: React.FC<Props> = ({ color = "text.primary", size = "20px" }) => {
  const envs = useEnvVariables();
  const isPrivalgoApp = isPrivalgo(envs.tenantName);

  const logo = (envs?.logoDataImage || envs?.logoUrl) ?? "";
  const hideLogo = envs.hideLogo;

  return (
    <MuiLink component={Link} href={Routes.HOME} color={color} fontSize={size} sx={link}>
      {hideLogo ? null : logo ? (
        <>
          {isEab(envs.tenantName) || isVitesse(envs.tenantName) || isBirmingham(envs.tenantName) ? (
            <Box sx={getLogoStyles(envs.tenantName)}>
              <Image
                src={logo}
                width={70}
                height={40}
                quality={100}
                layout="responsive"
                priority
                alt="logo"
              />
            </Box>
          ) : (
            <Image
              src={logo}
              style={getLogoStyles(envs.tenantName)}
              width={70}
              height={40}
              quality={100}
              layout={isMelton(envs.tenantName) ? "responsive" : ""}
              priority
              alt="logo"
            />
          )}
        </>
      ) : isPrivalgoApp ? (
        <>
          <Typography color="primary.main" fontSize="24px" letterSpacing="12px" sx={span}>
            PRIVALGO
          </Typography>
        </>
      ) : (
        <>
          CoP / IBAN Check{" "}
          <Typography component="span" color="secondary.main" fontSize={size} sx={span}>
            .
          </Typography>
        </>
      )}
    </MuiLink>
  );
};

export default Logo;
